import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2';
import {
  Switch,
  Route,
  useRouteMatch,
  NavLink,
  Redirect,
  useHistory,
} from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';

import { GiHamburgerMenu } from 'react-icons/gi';
import { GoSignOut, GoFileSubmodule } from 'react-icons/go';
import { RiDashboardFill } from 'react-icons/ri';
import { BsFilePost } from 'react-icons/bs';
import { BiGroup } from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';

import File from './File';
import Leads from './Leads';
import Posts from './Posts';

import api from '../../services/api';

import { Header, Main, SideBar, Container, Card } from './styles';
import logoIconPNG from '../../assets/logo-square.png';
import logoNamePNG from '../../assets/DivLoopers.png';

interface RoutesProps {
  exact?: boolean;
  path: string;
  component: JSX.Element;
  icon: JSX.Element;
  label: string;
}

interface ServerStatus {
  https: boolean;
  dnsProtection: boolean;
}

interface Count {
  count: number;
}

interface Dash {
  files?: Count;
  leads?: Count;
  posts?: Count;
  server?: ServerStatus;
}
const DashIndex: React.FC = () => {
  const history = useHistory();
  const [dashData, setDashData] = useState<Dash>({});

  useEffect(() => {
    const getDash = async (): Promise<void> => {
      try {
        const { data } = await api({}).get('/dash');

        console.log(data);
        if (data.success) {
          setDashData({ ...data.content });
          return;
        }
        Swal.fire(
          'Aviso!',
          'Ocorreu um erro, por favor recarregue e tente novamente',
          'warning',
        );
      } catch (err) {
        console.log(err);
        Swal.fire(
          'Aviso!',
          'Ocorreu um erro, por favor recarregue e tente novamente',
          'warning',
        );
      }
    };
    getDash();
  }, []);

  return (
    <Container>
      <Card>
        <strong>Postagens</strong>
        {dashData.posts ? (
          <span>{dashData.posts.count} no total</span>
        ) : (
          <Skeleton />
        )}

        <button type="button" onClick={() => history.push('/dashboard/posts')}>
          Ver mais
        </button>
      </Card>

      <Card>
        <strong>Leads</strong>
        {dashData.leads ? (
          <span>{dashData.leads.count} no total</span>
        ) : (
          <Skeleton />
        )}

        <button type="button" onClick={() => history.push('/dashboard/leads')}>
          Ver mais
        </button>
      </Card>

      <Card>
        <strong>Status do sistema</strong>
        {dashData.server ? (
          <>
            <span>
              https:&nbsp;
              <strong className={dashData.server.https ? 'green' : 'red'}>
                {dashData.server.https ? 'OK' : 'OFF'}
              </strong>
            </span>
            <span>
              DNS:&nbsp;
              <strong
                className={dashData.server.dnsProtection ? 'green' : 'red'}
              >
                {dashData.server.dnsProtection ? 'OK' : 'OFF'}
              </strong>
            </span>
          </>
        ) : (
          <Skeleton />
        )}
      </Card>
    </Container>
  );
};

const Dashboard: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { site, signOut } = useAuth();

  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [hoverMode, setHoverMode] = useState(false);

  const routes: RoutesProps[] = [
    {
      path: `${path}/home`,
      component: <DashIndex />,
      icon: <RiDashboardFill />,
      label: 'Dashboard',
    },
    {
      path: `${url}/leads`,
      component: <Leads />,
      icon: <BiGroup />,
      label: 'Leads',
    },
    {
      path: `${url}/posts`,
      component: <Posts />,
      icon: <BsFilePost />,
      label: 'Postagens',
    },
    {
      path: `${url}/files`,
      component: <File />,
      icon: <GoFileSubmodule />,
      label: 'Arquivos',
    },
  ];

  const handleLogout = (): void => {
    Swal.fire({
      title: 'Tem certeza que deseja sair?',
      icon: 'warning',
      cancelButtonText: 'Não, quero ficar.',
      cancelButtonColor: '#C1272D',
      confirmButtonText: 'Sim, quero sair.',
      confirmButtonColor: '#22B573',
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        signOut();
      }
    });
  };

  useEffect(() => {
    if (hoverMode) {
      setSideBarOpen(false);
    }
  }, [hoverMode]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (screenWidth <= 1200 && screenWidth !== 0) {
      setHoverMode(true);
    } else {
      setHoverMode(false);
    }
  }, [screenWidth]);

  return (
    <>
      <Header isOpen={sideBarOpen} isHoverMode={hoverMode}>
        <GiHamburgerMenu onClick={() => setSideBarOpen(!sideBarOpen)} />

        <div>
          <strong>{site}</strong>
          <GoSignOut onClick={handleLogout} />
        </div>
      </Header>

      <SideBar isOpen={sideBarOpen} isHoverMode={hoverMode}>
        <section>
          <img src={logoIconPNG} alt="DivLoopers" className="logoIcon" />
          <img src={logoNamePNG} alt="DivLoopers" className="logoName" />
        </section>
        <div>
          {routes.map((item) => (
            <NavLink key={item.label} to={item.path}>
              {item.icon}
              {item.label}
            </NavLink>
          ))}
        </div>
      </SideBar>

      <Main isOpen={sideBarOpen} isHoverMode={hoverMode}>
        <Switch>
          <Redirect exact path={`${path}`} to={`${path}/home`} />

          {routes.map((item) => (
            <Route key={item.path} path={item.path}>
              {item.component}
            </Route>
          ))}
        </Switch>
      </Main>
    </>
  );
};

export default Dashboard;
