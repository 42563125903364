import styled, { css } from 'styled-components';

interface ModalProps {
  isShown: boolean;
}

export const BackDrop = styled.div<ModalProps>`
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
    `}
  background-color: rgba(9, 77, 121, .75);
  transition: top 0.5s ease;
  z-index: 45;
`;

export const Container = styled.section<ModalProps>`
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  display: flex;

  .flex-all {
    display: flex;
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
    `}

  transition: top .5s ease;
  z-index: 50;

  > svg {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;

    top: 28px;
    right: 28px;

    /* color: #fff;
    fill: #fff; */
    transition: filter 0.5s ease;

    &:hover {
      filter: brightness(85%);
    }
  }
`;

export const Modal = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 3px 6px #aeaeae29;
  border-radius: 10px;

  width: 100%;
  max-width: 600px;

  transition: all 0.5s ease;

  > header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #ececec;

    padding: 24px 32px;

    width: 100%;
    > strong {
      font-size: 1.5rem;
      color: #375fc4;
      text-transform: capitalize;
    }
  }

  > svg {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;

    color: #213a77;

    width: 24px;
    height: 24px;

    transition: color 0.5s ease;

    &:hover {
      color: #375fc4;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 16px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      margin-left: 16px;

      > strong {
        font-size: 1.25rem;
        color: #375fc4;
      }

      > span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.1rem;
        color: #000;

        > svg {
          cursor: pointer;
          margin-left: 8px;
          width: 18px;
          height: 18px;

          color: #e33834;

          &:hover {
            filter: brightness(75%);
          }
        }
      }
    }
  }

  > article {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      border-radius: 8px;
      color: #fff;
      padding: 4px 12px;
      background-color: hsl(0, 94%, 43%);
      transition: filter 0.5s ease;

      &:hover {
        filter: brightness(75%);
      }

      + button {
        background-color: hsl(203, 85%, 48%);
        margin-left: 16px;
      }
    }
  }
`;
