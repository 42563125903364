import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import { formatTelNumber } from '../../../utils/format';
import api from '../../../services/api';

import { Container } from './styles';

interface LeadsObj {
  id: string | number;
  name: string;
  email: string;
  dateAdd: string;
  postName: string;
  message: string;
  tel: string;
}

const Leads: React.FC = () => {
  const [leadsList, setLeadsList] = useState<LeadsObj[]>([]);

  useEffect(() => {
    const getLeads = async (): Promise<void> => {
      try {
        const response = await api({}).get('/lead');

        if (response.data.success) {
          setLeadsList([...response.data.content]);
          return;
        }

        Swal.fire('Aviso!', 'Erro na busca de leads.', 'warning');
      } catch (err) {
        Swal.fire('Aviso!', 'Erro na busca de leads.', 'warning');
      }
    };
    getLeads();
  }, []);

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Nome</th>

            <th>E-mail</th>

            <th>Tel</th>

            <th>Local</th>

            <th>Adicionado em</th>

            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody>
          {leadsList.length ? (
            leadsList.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.tel ? formatTelNumber(item.tel) : item.tel}</td>
                <td>{item.postName}</td>
                <td>{new Date(item.dateAdd).toLocaleDateString()}</td>
                <td>{item.message}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Container>
  );
};

export default Leads;
