import React, { useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import ModalEditor, { Form } from '../../../components/ModalPostEdit';
import ModalPost from '../../../components/ModalPost';
import RowList from './RowList';
import api from '../../../services/api';
import { Container } from './styles';

export interface PostData {
  id: number;
  postName: string;
  postSlug: string;
  postDate: string;
  postData: {
    html: string;
    subTitle: string | null;
    tags?: string;
  };
  categoryName: string | null;
  fileHash: string | null;
  fileMain: string | null;
  fileId: number;
  img1Id: number;
}

export interface FilesObj {
  id: number;
  name: string;
  idPost: number;
  link: string;
  idImg: number;
}

export interface ItemPost {
  name: string;
  type: number;
  loop: PostData[];
}

const Posts: React.FC = () => {
  const [postList, setPostList] = useState<ItemPost[]>([]);

  const [fileList, setFileList] = useState<FilesObj[]>([]);

  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalPostShow, setModalPostShow] = useState(false);
  const [typeId, setTypeId] = useState(0);

  const [edit, setEdit] = useState<PostData | undefined>();

  useEffect(() => {
    const getPosts = async (): Promise<void> => {
      try {
        const { data } = await api({}).get('/post');
        console.log('FIXME', data);
        if (data.success) {
          setPostList([...data.content]);
          return;
        }

        Swal.fire('Aviso!', 'Erro na busca de posts.', 'warning');
      } catch (err) {
        Swal.fire('Aviso!', 'Erro na busca de posts.', 'warning');
      }
    };
    const getFiles = async (): Promise<void> => {
      try {
        const { data } = await api({}).get('/file');

        if (data.success) {
          console.log('Files', data.content);
          setFileList([...data.content]);
          return;
        }

        Swal.fire('Aviso!', 'Erro na busca de posts.', 'warning');
      } catch (err) {
        console.warn(err);
        Swal.fire('Aviso!', 'Erro na busca de posts.', 'warning');
      }
    };
    getFiles();
    getPosts();
  }, []);

  const onNewPost = async (
    form: Form,
    newTypeId: number,
    fileId: number,
  ): Promise<void> => {
    const Loader = Swal.mixin({
      title: 'Enviando post ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: 'rgba(0, 0, 0, 0)',
      backdrop: 'hsla(203, 100%, 30%, .75)',
      customClass: {
        title: 'whiteTxt',
      },
      onOpen: () => {
        Swal.showLoading();
      },
    });
    Loader.fire();
    console.log('New Post:', typeId, form);
    try {
      const { data } = await api({}).post('/post', {
        ...form,
        fileId: fileId || 0,
      });

      if (data.success) {
        const thisPostList = postList.filter(
          (item) => item.type === newTypeId,
        )[0];

        const newLoop: PostData[] = [
          ...thisPostList.loop,
          { ...data.content },
        ].sort((a, b) => (a.id > b.id ? -1 : 1));

        const newPostList = [
          ...postList.filter((item) => item.type !== newTypeId),
          {
            ...thisPostList,
            loop: newLoop,
          },
        ].sort((a, b) => (a.type > b.type ? 1 : -1));
        setPostList([...newPostList]);
        if (fileId) {
          setFileList([
            ...fileList.map((item) => {
              if (item.id === fileId) {
                return { ...item, idPost: fileId };
              }
              return { ...item };
            }),
          ]);
        }
        Loader.close();
        Swal.fire('Sucesso!', 'Post criado.', 'success');
        setModalPostShow(false);
        setTypeId(0);
        return;
      }
      Loader.close();
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro, por favor recarregue e tente novamente.',
        'warning',
      );
    } catch (err) {
      Loader.close();
      console.warn(err);
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro, por favor recarregue e tente novamente.',
        'warning',
      );
    }
  };

  const onEditPost = async (
    idPost: number,
    form: Form,
    editTypeId: number,
    fileId: number,
    fileImgId: number,
  ): Promise<void> => {
    const Loader = Swal.mixin({
      title: 'Editando post ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: 'rgba(0, 0, 0, 0)',
      backdrop: 'hsla(203, 100%, 30%, .75)',
      customClass: {
        title: 'whiteTxt',
      },
      onOpen: () => {
        Swal.showLoading();
      },
    });
    Loader.fire();
    console.log('EditPost:', idPost, form, editTypeId, fileId, fileImgId);
    try {
      const { data } = await api({}).put('/post', {
        ...form,
        id: idPost,
        fileId,
        img1Id: fileImgId,
      });
      console.log(data);
      if (data.success) {
        const thisPostList = postList.filter(
          (item) => item.type === editTypeId,
        )[0];

        const newLoop: PostData[] = [
          ...thisPostList.loop.filter((item) => item.id !== idPost),
          { ...data.content },
        ].sort((a, b) => (a.id > b.id ? -1 : 1));
        console.log(newLoop);

        const newPostList = [
          ...postList.filter((item) => item.type !== editTypeId),
          {
            ...thisPostList,
            loop: newLoop,
          },
        ].sort((a, b) => (a.type > b.type ? 1 : -1));

        setPostList([...newPostList]);
        if (fileId) {
          setFileList([
            ...fileList.map((item) => {
              if (item.id === fileId) {
                return { ...item, idPost };
              }
              if (item.idPost === idPost) {
                return { ...item, idPost: 0 };
              }
              return { ...item };
            }),
          ]);
        }
        Loader.close();
        Swal.fire('Sucesso!', 'Post editado.', 'success');
        setModalEditShow(false);
        setTypeId(0);
        setEdit(undefined);
        return;
      }
      Loader.close();
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro, por favor recarregue e tente novamente.',
        'warning',
      );
    } catch (err) {
      Loader.close();
      console.warn(err);
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro, por favor recarregue e tente novamente.',
        'warning',
      );
    }
  };

  const onDeletePost = async (
    postObj: PostData,
    itemPost: ItemPost,
  ): Promise<void> => {
    console.log('Delete Post:', postObj);
    Swal.fire({
      title: `Tem certeza que deseja deletar o post ${postObj.postName}?`,
      text: 'Está ação é irreversível!',
      icon: 'warning',
      cancelButtonText: 'Não, quero manter.',
      cancelButtonColor: '#C1272D',
      confirmButtonText: 'Sim, quero deletar.',
      confirmButtonColor: '#22B573',
      showCancelButton: true,
      reverseButtons: true,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await api({}).delete(`/post/ID/${postObj.id}`);
          if (data.success) {
            const filteredPosts = postList.filter(
              (item) => item.type !== itemPost.type,
            );
            const newPostList = [
              ...filteredPosts,
              {
                ...itemPost,
                loop: itemPost.loop.filter((item) => item.id !== postObj.id),
              },
            ].sort((a, b) => (a.type > b.type ? 1 : -1));
            setPostList([...newPostList]);
            Swal.fire('Sucesso!', 'Post deletado.', 'success');
          }
        } catch (err) {
          console.warn(err);
          Swal.fire(
            'Erro!',
            'Ocorreu um erro, por favor recarregue e tente novamente!',
            'error',
          );
        }
      } else {
        Swal.fire('Cancelado!', 'Deleção cancelada com sucesso.', 'success');
      }
    });
  };

  return (
    <Container>
      <ModalPost
        isShown={modalPostShow}
        typeId={typeId}
        files={fileList}
        onDone={onNewPost}
        onCancel={() => {
          setModalPostShow(false);
        }}
      />
      <ModalEditor
        isShown={modalEditShow}
        typeId={typeId}
        files={fileList}
        edit={edit}
        onDone={onEditPost}
        onCancel={() => {
          setModalEditShow(false);
        }}
      />

      {postList.length ? (
        postList
          .sort((a, b) => (a.type > b.type ? 1 : -1))
          .map((item) => (
            <RowList
              key={item.type}
              list={item}
              onNew={() => {
                setTypeId(item.type);
                setModalPostShow(true);
              }}
              onEdit={(post: PostData) => {
                setTypeId(item.type);
                setEdit(post);
                setModalEditShow(true);
              }}
              onDelete={(postObj: PostData, itemPost: ItemPost) => {
                onDeletePost(postObj, itemPost);
              }}
            />
          ))
      ) : (
        <Skeleton />
      )}
    </Container>
  );
};

export default Posts;
