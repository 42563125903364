import React, { useState } from 'react';

import { FiEdit3, FiXCircle } from 'react-icons/fi';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import { ItemPost, PostData } from '../index';

import { Container, Card } from './styles';

interface Props {
  list: ItemPost;
  onNew(): void;
  onEdit(post: PostData): void;
  onDelete(postObj: PostData, itemPost: ItemPost): void;
}

const RowList: React.FC<Props> = ({
  list,
  onNew,
  onDelete,
  onEdit,
  ...rest
}: Props) => {
  const [hide, setHide] = useState(false);

  return (
    <Container {...rest}>
      <div>
        <h1>{list.name}</h1>

        <div>
          <AiOutlineEye
            onClick={() => setHide(!hide)}
            className={hide ? 'eye hidden' : 'eye'}
          />
          <AiOutlineEyeInvisible
            onClick={() => setHide(!hide)}
            className={hide ? 'eye' : 'eye hidden'}
          />

          <button type="button" onClick={onNew}>
            Novo
          </button>
        </div>
      </div>

      <div className={hide ? 'hidden' : ''}>
        {list.loop.map((item) => (
          <Card
            key={item.id}
            img={`https://storage.divloopers.com/${item.fileMain}`}
          >
            <article />
            <article />

            <strong>{item.postName}</strong>

            <div>
              <FiEdit3 onClick={() => onEdit(item)} />
              <FiXCircle onClick={() => onDelete(item, list)} />
            </div>

            <span>SubTítulo: {item.postData.subTitle || 'Sem subtítulo'}</span>
            <span>Criado em: {item.postDate}</span>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default RowList;
