import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface UploadProps {
  isDragActive: boolean;
  isDragReject: boolean;
  refKey?: string;
  [key: string]: any;
  type?: 'error' | 'success' | 'default';
}

const dragActive = css`
  border-color: #12a454;
`;

const dragReject = css`
  border-color: #e83f5b;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragActive && dragActive}

  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragReject && dragReject}
`;

const messageColors = {
  default: '#4D4F5C',
  error: '#e83f5b',
  success: '#12a454',
};

export const UploadMessage = styled.p`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;

  strong {
    margin-top: 4px;
    font-size: 1.25rem;
    font-weight: bold;
    color: ${({ type }: UploadProps) => messageColors[type || 'default']};
  }

  span {
    text-align: center;
    margin-top: 4px;
    font-size: 1rem;
    color: ${({ type }: UploadProps) => messageColors[type || 'default']};
  }

  justify-content: center;
  align-items: center;
`;
