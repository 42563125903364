import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface IdName {
  id: number | string;
  name: string;
}

interface Pref {
  category: IdName[];
  type: IdName[];
}

export interface AuthState {
  token: string;
  site: string;
  pref: Pref;
}

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  token: string;
  site: string;
  pref: Pref;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(
    (): AuthState => {
      const token = localStorage.getItem('@DivLoopers:token');
      const site = localStorage.getItem('@DivLoopers:site');
      const pref = localStorage.getItem('@DivLoopers:pref');

      if (token && site && pref) {
        return { token, site, pref: JSON.parse(pref) };
      }

      return {} as AuthState;
    },
  );

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api({}).post('/auth', {
      mail: username,
      pass: password,
    });

    const { content, success, exception } = response.data;

    if (!success) {
      console.warn('Error API', response);
      throw Error('404');
    }

    if (success && exception === false) {
      throw Error('403');
    }

    const { auth, site, pref } = content;

    localStorage.setItem('@DivLoopers:token', auth);
    localStorage.setItem('@DivLoopers:site', site);
    localStorage.setItem('@DivLoopers:pref', JSON.stringify(pref));

    setData({ token: auth, site, pref });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@DivLoopers:token');
    localStorage.removeItem('@DivLoopers:site');
    localStorage.removeItem('@DivLoopers:pref');
    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        pref: data.pref,
        site: data.site,
        token: data.token,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
