import React from 'react';

import { AuthProvider } from './auth';
// import { LoaderProvider } from './loader';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>{children}</AuthProvider>
);

export default AppProvider;
