import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import { BiUser } from 'react-icons/bi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { VscLock } from 'react-icons/vsc';

import { useAuth } from '../../hooks/auth';

import { Container, Row, Button } from './styles';
import logoPNG from '../../assets/logoBlue.png';

interface Form {
  username?: string;
  password?: string;
  userErr?: string;
  passErr?: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const [form, setForm] = useState<Form>({});
  const [showPass, setShowPass] = useState(true);

  const handleSubmit = async (): Promise<void> => {
    const { username, password } = form;
    console.log(form);
    if (!username || !password) {
      let userErr;
      let passErr;

      if (!username) userErr = 'Preencha o campo corretamente.';
      if (!password) passErr = 'Preencha o campo corretamente.';

      setForm({ ...form, passErr, userErr });
      return;
    }

    const Loader = Swal.mixin({
      title: 'Carregando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: 'rgba(0, 0, 0, 0)',
      backdrop: 'hsla(203, 100%, 30%, .75)',
      customClass: {
        title: 'whiteTxt',
      },
      onOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      Loader.fire();
      await signIn({ username, password });
      Loader.close();
      history.push('/dashboard');
      return;
    } catch (err) {
      console.log('im error', err);
      Loader.close();
      if (err.message === '403') {
        Swal.fire(
          'Aviso!',
          'Os dados estão inválidos, tente novamente.',
          'warning',
        );
        return;
      }

      Swal.fire(
        'Erro interno!',
        'Por favor tente novamente mais tarde.',
        'error',
      );
    }
  };

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    }
  };

  // useEffect(() => {
  //   document.addEventListener('keydown', onKeyDown, false);
  //   return () => {
  //     document.removeEventListener('keydown', onKeyDown, false);
  //   };
  // }, []);

  return (
    <Container onKeyDown={(event) => onKeyDown(event)}>
      <img src={logoPNG} alt="DivLoopers" />

      <div>
        <h1>Bem-vindo!</h1>
        <span>Faça seu login.</span>

        <Row className={form.userErr ? 'isErrored' : ''}>
          <strong>E-mail</strong>
          <div>
            <BiUser />
            <input
              type="text"
              onChange={(event) => {
                setForm({
                  ...form,
                  username: event?.target.value,
                  userErr: '',
                });
              }}
              value={form.username || ''}
            />
          </div>
          <span className={form.userErr ? '' : 'hidden'}>{form.userErr}</span>
        </Row>

        <Row className={form.passErr ? 'isErrored' : ''}>
          <strong>Senha</strong>
          <div>
            <VscLock />
            <input
              type={showPass ? 'password' : 'text'}
              onChange={(event) => {
                setForm({
                  ...form,
                  password: event?.target.value,
                  passErr: '',
                });
              }}
              value={form.password || ''}
            />
            <AiOutlineEye
              className={showPass ? 'eye hidden' : 'eye'}
              onClick={() => setShowPass(!showPass)}
            />
            <AiOutlineEyeInvisible
              className={showPass ? 'eye' : 'eye hidden'}
              onClick={() => setShowPass(!showPass)}
            />
          </div>
          <span className={form.userErr ? '' : 'hidden'}>{form.passErr}</span>
        </Row>

        <Button onClick={handleSubmit}>Entrar</Button>
      </div>
    </Container>
  );
};

export default Login;
