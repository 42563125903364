import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Swal from 'sweetalert2';
import { FiX } from 'react-icons/fi';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { IoImagesOutline, IoImagesSharp } from 'react-icons/io5';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useAuth } from '../../hooks/auth';

import { Container, BackDrop, Modal, Content } from './styles';
import { ItemPost, PostData, FilesObj } from '../../pages/Dashboard/Posts';

export interface Form {
  title?: string;
  subTitle?: string;
  category?: number;
  type?: number;
  editor?: unknown;
  tags?: string;
}

interface ModalProps {
  isShown: boolean;
  typeId: number;
  files: FilesObj[];
  onDone(
    id: number,
    form: Form,
    typeId: number,
    fileId?: number,
    imgId?: number,
  ): void;
  edit?: PostData;
  onCancel(): void;
}

const ModalPostEdit: React.FC<ModalProps> = ({
  isShown,
  onCancel,
  typeId,
  files,
  onDone,
  edit,
}: ModalProps) => {
  const { pref } = useAuth();

  const [fileList, setFileList] = useState<FilesObj[]>([...files]);

  const [fileFilter, setFileFilter] = useState(1);
  const [destaque, setDestaque] = useState(0);
  const [img, setImg] = useState(0);

  const generateTitle = (): string => {
    if (!typeId) {
      return '';
    }
    const typeFilter = pref.type.filter((item) => item.id === typeId);
    return `Editar ${typeFilter[0].name}`;
  };

  const [form, setForm] = useState<Form>({});
  const [editorTxt, setEditorTxt] = useState<unknown>('');

  const handleSave = (): void => {
    if (edit) {
      const { title } = form;
      console.log(form, editorTxt);
      if (!editorTxt || !title) {
        Swal.fire(
          'Aviso!',
          'Preencha no mínimo um conteúdo, título!',
          'warning',
        );
        return;
      }
      onDone(edit.id, { ...form, editor: editorTxt }, typeId, destaque, img);
    }
  };

  useEffect(() => {
    if (isShown && edit) {
      console.log(edit);
      const typeFilter = pref.type.filter((item) => item.id === typeId);
      if (typeFilter.length && edit) {
        setFileList(files);
        setDestaque(edit.fileId);
        setImg(edit.img1Id);
        setEditorTxt(edit.postData.html);
        setForm({
          title: edit.postName,
          subTitle: edit.postData.subTitle || '',
          tags: edit.postData.tags,
          category: 0,
          type: (typeFilter[0].id as number) || 0,
        });
        return;
      }
      return;
    }
    if (!isShown) {
      setForm({ category: 0, type: 0 });
    }
  }, [isShown]);

  const toggleStar = (fileId: number): void => {
    setDestaque(fileId);
  };

  const toggleImg = (fileId: number): void => {
    setImg(fileId);
  };

  const generateLinkImg = (link: string): unknown => {
    return `<figure class="image">
    <img src="${link}" alt="">
    <figcaption></figcaption>
    </figure>`;
  };
  const modal = (
    <>
      <BackDrop isShown={isShown} />
      <Container isShown={isShown}>
        <Modal>
          <FiX onClick={onCancel} />

          <header>
            <strong>{generateTitle()}</strong>
          </header>

          <Content>
            <div>
              <div>
                <div>
                  <span>Título</span>
                  <input
                    type="text"
                    onChange={(event) => {
                      const { value } = event?.target;
                      setForm({ ...form, title: value });
                    }}
                    value={form.title}
                  />
                </div>

                <div>
                  <span>Subtítulo</span>
                  <input
                    type="text"
                    onChange={(event) => {
                      const { value } = event?.target;
                      setForm({ ...form, subTitle: value });
                    }}
                    value={form.subTitle || ''}
                  />
                </div>

                <div>
                  <span>Tags:</span>
                  <input
                    type="text"
                    onChange={(event) => {
                      const { value } = event?.target;
                      setForm({ ...form, tags: value });
                    }}
                    value={form.tags || ''}
                  />
                </div>
              </div>

              <div>
                <span>Lista de imagens:</span>
                <div>
                  {fileList.length &&
                    fileList
                      .filter((item, index) => {
                        const startIndex =
                          fileFilter === 1 ? 1 : 1 + 4 * (fileFilter - 1);

                        return (
                          index + 1 >= startIndex && index + 1 <= 4 * fileFilter
                        );
                      })
                      .map((item, index) => (
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              setEditorTxt(
                                `${editorTxt || ''} ${generateLinkImg(
                                  item.link,
                                )}`,
                              );
                            }}
                          >
                            {item.name}
                          </button>
                          {item.id === destaque ? (
                            <AiFillStar onClick={() => toggleStar(item.id)} />
                          ) : (
                            <AiOutlineStar
                              onClick={() => toggleStar(item.id)}
                            />
                          )}

                          {item.id === img ? (
                            <IoImagesSharp onClick={() => toggleImg(item.id)} />
                          ) : (
                            <IoImagesOutline
                              onClick={() => toggleImg(item.id)}
                            />
                          )}
                        </div>
                      ))}
                </div>

                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setFileFilter(fileFilter === 1 ? 1 : fileFilter - 1);
                    }}
                  >
                    Anterior
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const total = fileList.length;
                      const calc = fileFilter === 0 ? 1 : total / 4;
                      if (fileFilter >= calc) return;

                      setFileFilter(fileFilter + 1);
                    }}
                  >
                    Próximo
                  </button>
                </div>
              </div>
            </div>

            <div>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  language: 'pt',
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: 'Parágrafo',
                        class: 'ck-heading_paragraph',
                      },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Titulo Grande',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Titulo menor',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: 'SubTitulo',
                        class: 'ck-heading_heading2',
                      },
                    ],
                  },
                }}
                onReady={(editor: any) => {
                  editor.setData('');
                }}
                data={editorTxt || ''}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();

                  // console.log({ event, editor, data });
                  // console.log('editor', data, editorTxt);
                  setEditorTxt(data);
                }}
              />
            </div>

            <div>
              <select
                name="Categoria"
                value={form.category}
                onChange={(event) => {
                  const { value } = event?.target;
                  if (parseInt(value, 10) !== form.category) {
                    setForm({ ...form, category: parseInt(value, 10) });
                  }
                }}
              >
                <option value="0">Sem categoria</option>
                {pref.category.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>

              <select name="Tipo" value={form.type} disabled>
                {pref.type.map((item) => (
                  <option value={item.id} selected={item.id === typeId}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </Content>

          <div>
            <button type="button" onClick={onCancel}>
              Cancelar
            </button>

            <button type="button" onClick={handleSave}>
              Salvar
            </button>
          </div>
        </Modal>
      </Container>
    </>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default ModalPostEdit;
