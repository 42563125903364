import styled from 'styled-components';
import CardPNG from '../../../../assets/card.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 5px 15px #00000030;

  border-radius: 12px;
  padding: 12px 16px;

  > div:nth-child(1) {
    width: 100%;
    border-bottom: 1px solid #000000;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > h1 {
      text-transform: capitalize;
      font-size: 1.75rem;
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;

      > svg {
        cursor: pointer;
        color: #000000;
        position: absolute;
        left: -36px;
        top: calc(50% - 12px);

        width: 24px;
        height: 24px;
        opacity: 1;

        transition: opacity 0.5s ease;

        &.eye {
          &:nth-child(2) {
            color: #00000030;
          }
        }
        &.hidden {
          opacity: 0;
        }
      }

      > button {
        border-radius: 8px;
        background-color: hsl(203, 85%, 48%);
        color: #fff;
        padding: 4px 12px;

        transition: filter 0.5s ease;
        &:hover {
          filter: brightness(75%);
        }
      }
    }
  }

  > div:nth-child(2) {
    margin-top: 8px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    max-height: unset;
    transition: max-height 0.5s ease, height;

    > div {
      opacity: 1;
      visibility: visible;
      z-index: 25;
    }

    &.hidden {
      max-height: 0;

      > div {
        opacity: 0;
        visibility: hidden;
        z-index: -1;
      }
    }
  }
`;

interface CardProps {
  img: string;
}
export const Card = styled.div<CardProps>`
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: rgba(0, 0, 0, 0.01);

  box-shadow: 0px 5px 15px #00000030;
  border-radius: 12px;
  padding: 12px 16px;
  margin: 12px;

  > article:first-child {
    border-radius: 12px;

    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-size: cover;
    /* background-image: url(${CardPNG}); */
    background-image: url(${(props) => props.img});
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: blur(1px);
  }

  > article:nth-child(2) {
    position: absolute;
    border-radius: 12px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.3);
  }

  flex-basis: calc(100% / 4 - 24px);
  min-width: calc(100% / 4 - 24px);
  min-height: 150px;

  display: flex;
  flex-direction: column;

  > div {
    z-index: 25;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;

    right: 8px;
    top: 12px;

    > svg {
      cursor: pointer;
      width: 22px;
      height: 22px;
      background-color: rgba(255, 255, 255, 0.9);
      color: #0097f6;

      transition: filter 0.5s ease;
      &:hover {
        background-color: #0097f6;
        color: rgba(255, 255, 255, 0.9);
      }

      + svg {
        margin-left: 6px;
      }
    }
  }

  > strong {
    z-index: 25;

    max-width: calc(100% - 42px);
    width: 100%;
    display: flex;

    word-wrap: anywhere;

    font-size: 1.33rem;
    color: #0097f6;
  }

  > span {
    z-index: 25;

    margin-top: 8px;
  }
`;
