import React, { ReactNode } from 'react';

import { TiCloudStorage } from 'react-icons/ti';
import { FiX, FiCheck } from 'react-icons/fi';

import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload: (files: File[]) => void;
}

const Upload: React.FC<UploadProps> = ({ onUpload }: UploadProps) => {
  const renderDragMessage = (
    isDragActive: boolean,
    isDragRejest: boolean,
  ): ReactNode => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <TiCloudStorage size={64} color="#4D4F5C" />
          <strong>ARRASTE & SOLTE</strong>
          <span>seus arquivos, ou clique aqui para procurar</span>
        </UploadMessage>
      );
    }

    if (isDragRejest) {
      return (
        <UploadMessage type="error">
          <FiX size={64} color="#e83f5b" />
          <strong>Arquivo não suportado</strong>
          <span>aceitamos somente arquivos: .jpg, .jpeg, .png</span>
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        <FiCheck size={64} color="#12a454" />
        <strong>Solte o arquivo aqui</strong>
        <span>após o envio você o verá na lista de anexo ao lado</span>
      </UploadMessage>
    );
  };

  return (
    <Dropzone
      accept="image/png, image/jpeg, image/jpg, image/*"
      onDropAccepted={(files) => onUpload(files)}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }): any => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
