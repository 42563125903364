import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import Swal from 'sweetalert2';
import { FiX } from 'react-icons/fi';
import { BackDrop, Container, Modal } from './styles';

import UploadZone from '../UploadZone';

interface FileObj {
  file: File;
  type: string;
  name: string;
}

interface ModalProps {
  isShown: boolean;
  onCancel(): void;
  onSubmit(file: FileObj): void;
}

const ModalFile: React.FC<ModalProps> = ({
  isShown,
  onCancel,
  onSubmit,
}: ModalProps) => {
  const [fileUpload, setFileUpload] = useState<FileObj[]>([]);

  const handleFileSubmit = async (files: File[]): Promise<void> => {
    setFileUpload([
      {
        file: files[0],
        name: files[0].name,
        type: files[0].type,
      },
    ]);
  };

  useEffect(() => {
    if (!isShown) {
      setFileUpload([]);
    }
  }, [isShown]);

  const modal = (
    <>
      <BackDrop isShown={isShown} />
      <Container isShown={isShown}>
        {isShown && (
          <FocusLock className="flex-all">
            <Modal>
              <FiX onClick={onCancel} />
              <header>
                <strong>Enviar arquivo</strong>
              </header>

              <div>
                <UploadZone onUpload={handleFileSubmit} />

                <div>
                  <strong>Arquivo:</strong>
                  <span>
                    {fileUpload[0] && (
                      <>
                        {fileUpload[0].name}
                        <FiX onClick={() => setFileUpload([])} />
                      </>
                    )}
                  </span>
                </div>
              </div>

              <article>
                <button type="button" onClick={onCancel}>
                  Cancelar
                </button>
                <button type="button" onClick={() => onSubmit(fileUpload[0])}>
                  Salvar
                </button>
              </article>
            </Modal>
          </FocusLock>
        )}
      </Container>
    </>
  );
  return ReactDOM.createPortal(modal, document.body);
};

export default ModalFile;
