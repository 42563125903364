import axios, { AxiosInstance } from 'axios';

interface DTO {
  token?: string;
  storage?: boolean;
}

const api = ({ token, storage = false }: DTO): AxiosInstance => {
  let tokenBearer = '';

  if (token) {
    tokenBearer = `Bearer ${token}`;
  } else {
    const tokenStorage = localStorage.getItem('@DivLoopers:token');

    // FIXME LATER: SIZE OF THIS LENGTH
    if (!tokenStorage || tokenStorage.length < 1) {
      localStorage.removeItem('@DivLoopers:token');
    }

    tokenBearer = `Bearer ${tokenStorage}`;
  }

  return axios.create({
    // baseURL: `http://localhost:37371/${storage ? 'storage' : 'api'}/`,
    baseURL: `https://cliente.divloopers.com/${storage ? 'storage' : 'api'}/`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: tokenBearer,
    },
  });
};

export default api;
