import React from 'react';

import { useHistory } from 'react-router-dom';

const Error404: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <h1>Página não encontrada 404</h1>
      <button
        type="button"
        onClick={() => {
          history.push('/');
        }}
      >
        Me leve para o login
      </button>
    </div>
  );
};

export default Error404;
