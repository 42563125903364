import styled from 'styled-components';
import { fadeIn } from '../../../styles/global';

export const Container = styled.section`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  max-height: calc(100vh - 75px - 24px - 20px);
  padding: 24px;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards normal;

  table {
    border: none;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 16px #aeaeae40;
    display: flex;
    flex-direction: column;

    margin-bottom: 32px;
    flex: 1;
    /* min-height: 100%; */
    width: 100%;
    max-width: 100%;

    thead {
      display: flex;
      border-bottom: 2px solid #00000050;

      tr {
        display: flex;
        width: 100%;

        th {
          padding: 6px 12px;
          color: #094d79;
          font-size: 1.2rem;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          + th {
            border-left: 2px solid #f9f9f9;
          }

          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 150px;
            max-width: 150px;
          }

          &:nth-child(4) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 175px;
            max-width: 175px;
          }

          &:nth-child(6) {
            flex: 1;
          }
        }
      }
    }

    tbody {
      overflow-y: auto;
      max-height: calc(100vh - 75px - 24px - 52px);
      tr {
        display: flex;
        opacity: 0;
        animation: ${fadeIn} 0.25s ease forwards normal;
        &:nth-child(even) {
          background-color: #00000010;
        }
        + tr {
          border-top: 2px solid #e3e3e3;
        }
        td {
          padding: 6px 12px;
          color: #707070;
          font-size: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            word-break: break-word;
            width: 15%;
          }

          &:nth-child(3) {
            width: 150px;
            max-width: 150px;
          }

          &:nth-child(4) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 175px;
            max-width: 175px;
          }

          &:nth-child(6) {
            flex: 1;
            min-height: 40px;
          }

          + td {
            border-left: 2px solid #e3e3e3;
          }
        }
      }
    }
  }
`;
