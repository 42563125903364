import React, { useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import { FiEdit3, FiXCircle } from 'react-icons/fi';

import api from '../../../services/api';
import ModalFile from '../../../components/ModalFile';

import { Container, Card, Content } from './styles';

interface FileData {
  file: File;
  type: string;
  name: string;
}

interface FileObj {
  id: string | number;
  name: string;
  hash: string;
  dateAdd: string;
  postName: string | null;
  fileMain: string;
}

const File: React.FC = () => {
  const [filesList, setFilesList] = useState<FileObj[]>([]);

  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    const getFiles = async (): Promise<void> => {
      try {
        const { data } = await api({}).get('/file');
        console.log('FIXME', data);
        if (data.success) {
          setFilesList([...data.content]);
          return;
        }

        Swal.fire('Aviso!', 'Erro na busca de arquivos.', 'warning');
      } catch (err) {
        Swal.fire('Aviso!', 'Erro na busca de arquivos.', 'warning');
      }
    };
    getFiles();
  }, []);

  const handleDeleteFile = async (file: FileObj): Promise<void> => {
    console.log('Delete:', file);
    Swal.fire({
      title: `Tem certeza disso?!`,
      text: 'Está ação é irreversível!',
      icon: 'warning',
      cancelButtonText: 'Não, quero manter.',
      cancelButtonColor: '#C1272D',
      confirmButtonText: 'Sim, quero deletar.',
      confirmButtonColor: '#22B573',
      showCancelButton: true,
      reverseButtons: true,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await api({}).delete(`/file/ID/${file.id}`);

          if (data.success) {
            setFilesList([...filesList.filter((item) => item.id !== file.id)]);
            Swal.fire('Sucesso!', 'Arquivo deletado.', 'success');
            setModalShown(false);
            return;
          }

          Swal.fire(
            'Aviso!',
            'Ocorreu um erro, recarregue e tente novamente.',
            'warning',
          );
        } catch (err) {
          Swal.fire(
            'Aviso!',
            'Ocorreu um erro, recarregue e tente novamente.',
            'warning',
          );
        }
      } else {
        Swal.fire('Cancelado!', 'Nenhum arquivo foi deletado.', 'success');
      }
    });
  };

  const handleNewFile = async (file: FileData): Promise<void> => {
    const Loader = Swal.mixin({
      title: 'Enviando arquivo ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: 'rgba(0, 0, 0, 0)',
      backdrop: 'hsla(203, 100%, 30%, .75)',
      customClass: {
        title: 'whiteTxt',
      },
      onOpen: () => {
        Swal.showLoading();
      },
    });
    Loader.fire();
    const data = new FormData();

    data.append('file', file.file, file.name);

    try {
      const response = await api({ storage: true }).post('/file', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      Loader.close();
      console.log(response);
      if (response.data.success) {
        setFilesList([{ ...response.data.content }, ...filesList]);
        Swal.fire('Sucesso!', 'Arquivo enviado.', 'success');
        setModalShown(false);
        return;
      }
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro por favor, recarregue e tente novamente.',
        'error',
      );
    } catch (err) {
      console.log(err.response);
      Loader.close();
      Swal.fire(
        'Aviso!',
        'Ocorreu um erro por favor, recarregue e tente novamente.',
        'error',
      );
    }
  };

  return (
    <Container>
      <ModalFile
        isShown={modalShown}
        onSubmit={handleNewFile}
        onCancel={() => {
          setModalShown(false);
        }}
      />

      <Content>
        <div>
          <h1>Arquivos</h1>

          <div>
            <button
              type="button"
              onClick={() => {
                setModalShown(true);
              }}
            >
              Novo
            </button>
          </div>
        </div>

        <div>
          {filesList.length ? (
            filesList.map((item) => (
              <Card
                key={item.id}
                img={`https://storage.divloopers.com/${item.fileMain}`}
              >
                <article />
                <article />

                <strong>{item.name}</strong>

                <div>
                  <FiXCircle onClick={() => handleDeleteFile(item)} />
                </div>

                <span>
                  Criado em: {new Date(item.dateAdd).toLocaleDateString()}&nbsp;
                  {new Date(item.dateAdd).toLocaleTimeString()}
                </span>

                <span>Post: {item.postName ? item.postName : 'Nenhum.'}</span>
              </Card>
            ))
          ) : (
            <Skeleton count={4} />
          )}
        </div>
      </Content>
    </Container>
  );
};

export default File;
