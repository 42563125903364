import { createGlobalStyle, keyframes } from 'styled-components';

export default createGlobalStyle`
  .whiteTxt {
    color: #fff;
  }
  .blackTxt {
    color: #000;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    outline: none;
    background-color: hsl(205, 20%, 80%);
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
    @media(max-width: 2000px) {
      font-size: 16px;
    }

    @media(max-width: 1600px) {
      font-size: 14px;
    }

    @media(max-width: 1400px) {
      font-size: 12px;
    }
  }

  body {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  #root {
    position: relative;
    display: flex;
    flex: 1;
  }


  input, button, textarea, select {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.15rem;
    border: 0;
    &:focus {
      outline: none;
    }
  }

  /* input[type='date']::-webkit-calendar-picker-indicator,
  input[type='date']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  } */

  button {
    cursor: pointer;
    font-family: 'Poppins', 'sans-serif';
    font-weight: 600;
  }

  a {
    color:inherit;
    text-decoration: none;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

`;

export const fadeIn = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const fadeInLeft = keyframes`
  0% {
      opacity: 0;
      transform: translateX(-40px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;

export const fadeInRight = keyframes`
  0% {
      opacity: 0;
      transform: translateX(40px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;
