import styled from 'styled-components';
import { fadeIn } from '../../../styles/global';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  padding: 24px;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards normal;

  > span {
    display: flex;
    flex: 1;
    height: 100%;
    > span {
      height: 100%;
      flex: 1;
    }
  }

  > div {
    + div {
      margin-top: 24px;
    }
  }
`;
