export const formatContaBanco = (conta: string | number): string => {
  const contaString = conta.toString();
  const contaSize = contaString.length;
  return `${contaString.substr(0, contaSize - 1)}-${contaString.substr(
    contaSize - 1,
    contaSize,
  )}`;
};

export const formatCEP = (cep: string | number): string => {
  return cep.toString().replace(/(\d{5})(\d{3})$/, '$1-$2');
};

export const formatPhoneNumber = (phone: string | number): string => {
  return phone
    .toString()
    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2$3-$4');
};

export const formatTelNumber = (phone: string | number): string => {
  return phone.toString().replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
};

export const formatCpfCnpj = (value: string): string => {
  if (value.length <= 11)
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

export const formatPercentage = (
  value: number,
  withOperator = true,
): string | undefined => {
  if (value === 0) {
    return '0%';
  }
  if (withOperator) {
    // return `${value.toFixed(2)}%`;
    return value > 0 ? `+${value.toFixed(2)}%` : `${value.toFixed(2)}%`;
  }
  if (value > 0) {
    return `${value.toFixed(2)}%`;
  }
  if (value < 0) {
    const valueMinusRemoved = value * -1;
    return `${valueMinusRemoved.toFixed(2)}%`;
  }
  return undefined;
};

export const formatCurrency = (value?: number, withPrefix = true): string => {
  return Intl.NumberFormat('pt-BR', {
    style: withPrefix ? 'currency' : undefined,
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value || 0);
};

export const dateToString = (date: Date): string => {
  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const yy = date.getFullYear();

  return `${dd}/${mm}/${yy}`;
};

export const dateToInputString = (date: Date): string => {
  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const yy = date.getFullYear();

  const hh = date.getHours().toString().padStart(2, '0') || '12';
  const min = date.getMinutes().toString().padStart(2, '0') || '00';

  return `${yy}-${mm}-${dd} ${hh}:${min}`;
};
