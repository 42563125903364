import styled, { css } from 'styled-components';
import { fadeIn, fadeInLeft, fadeInRight } from '../../styles/global';

interface Props {
  isOpen: boolean;
  isHoverMode: boolean;
}

export const BackDrop = styled.div<Props>`
  position: absolute;
  left: -100vw;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: left 0.75s ease;
  z-index: 20;
  ${(props) =>
    props.isOpen &&
    props.isHoverMode &&
    css`
      left: 0;
    `}
`;

export const Header = styled.header<Props>`
  position: fixed;
  background-color: #0097f6;
  box-shadow: 10px 10px 10px #00000030;
  opacity: 0;
  top: 0;
  right: 0;

  height: 100%;
  max-height: 75px;

  width: 100%;
  max-width: calc(100vw - 75px);

  transition: max-width 0.3s ease-in-out;
  animation: ${fadeIn} 0.5s ease forwards normal;

  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 24px;

  ${(props) =>
    props.isOpen &&
    !props.isHoverMode &&
    css`
      max-width: calc(100% - 250px);
    `}

  > svg {
    cursor: pointer;
    width: 32px;
    height: 32px;

    fill: rgba(255, 255, 255, 1);

    transition: fill 0.5s ease;
    &:hover {
      fill: rgba(255, 255, 255, 0.5);
    }
  }

  > div {
    display: flex;
    align-items: center;

    > strong {
      color: #fff;
      font-size: 1.5rem;
    }

    > svg {
      margin-left: 16px;

      cursor: pointer;
      width: 32px;
      height: 32px;

      fill: rgba(255, 255, 255, 1);

      transition: fill 0.5s ease;
      &:hover {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export const SideBar = styled.section<Props>`
  position: fixed;
  background-color: rgba(0, 72, 117, 0.7);
  box-shadow: 10px 0px 20px #00000030;
  opacity: 0;
  left: 0;
  top: 0;

  max-width: 75px;
  width: 100%;
  height: 100%;

  transition: left 0.25s ease-in-out, max-width 0.25s ease-in-out;
  animation: ${fadeInLeft} 0.5s ease forwards normal;

  display: flex;
  flex-direction: column;

  transition: max-width 0.3s ease-in-out;

  > section {
    display: flex;
    max-height: 75px;
    height: 100%;
    width: 100%;
    background-color: #fff;

    padding: 8px;

    align-items: center;
    justify-content: center;

    > img {
      opacity: 0;

      height: auto;
      width: 100%;
      max-height: 75px;
      max-height: 250px;

      transition: all 0.5s ease-in-out;

      &.logoIcon {
        position: unset;
        opacity: 1;
        z-index: 25;
      }

      &.logoName {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }

      ${(props) =>
        props.isOpen &&
        css`
          &.logoIcon {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          &.logoName {
            position: unset;
            opacity: 1;
            z-index: 25;
          }
        `}
    }
  }

  > div {
    margin-top: 16px;

    flex: 1;
    display: flex;
    flex-direction: column;

    > a {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      color: #fff;
      font-size: 0px;

      padding: 4px 16px;
      width: 100%;
      transition: filter 0.5s ease, background-color 0.5s ease,
        font-size 0.5s ease;

      &.active {
        background-color: rgba(0, 72, 117, 0.85);
      }

      &:hover {
        filter: brightness(75%);
      }

      > svg {
        margin-right: 8px;
        width: 32px;
        height: 32px;
      }
    }
  }

  ${(props) =>
    props.isOpen &&
    css`
      max-width: 250px;

      a {
        font-size: 1.25rem !important;
      }
    `}
`;

export const Main = styled.main<Props>`
  display: flex;
  position: absolute;

  height: 100%;
  width: 100%;

  max-height: calc(100vh - 75px);
  max-width: calc(100vw - 75px);

  top: 75px;
  left: 75px;

  opacity: 0;
  transition: left 0.3s ease-in-out, min-width 0.3s ease-in-out;
  animation: ${fadeInRight} 0.5s ease forwards normal;

  ${(props) =>
    props.isOpen &&
    !props.isHoverMode &&
    css`
      left: 250px;
      max-width: calc(100vw - 250px);
    `}
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 24px;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards normal;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 5px 15px #00000030;

  border-radius: 12px;
  padding: 12px 16px;

  min-width: calc(100% / 3 - 12px);
  max-width: calc(100% / 3 - 12px);
  min-height: 150px;
  max-height: 175px;

  transition: width 0.5s ease;

  > strong {
    font-size: 1.75rem;
  }

  > span {
    font-size: 1.25rem;
    margin-top: 12px;
    width: 100%;

    > strong {
      margin-left: 6px;
      &.red {
        color: #c1272d;
      }
      &.green {
        color: #22b573;
      }
    }
  }

  > button {
    margin-top: 16px;
    border-radius: 8px;
    background-color: hsl(203, 85%, 48%);
    color: #fff;
    padding: 4px 12px;

    transition: filter 0.5s ease;
    &:hover {
      filter: brightness(75%);
    }
  }
`;
