import styled from 'styled-components';
import { fadeIn } from '../../styles/global';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  margin: 24px auto 0;
  max-width: 750px;
  width: 100%;

  > img {
    height: auto;
    width: 100%;
    max-width: 375px;
    margin: 0 auto 0;
    animation: ${fadeIn} 0.5s ease forwards normal;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;

    > h1 {
      opacity: 0;
      color: #1b75bf;
      text-align: center;
      font-size: 2.5rem;
      margin-top: 50px;
      animation: ${fadeIn} 0.5s ease forwards normal 0.5s;
    }

    > span {
      opacity: 0;
      color: #def2fe;
      margin-top: 8px;
      text-align: center;
      font-size: 1.35rem;
      font-weight: 600;
      animation: ${fadeIn} 0.5s ease forwards normal 0.9s;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards normal 1.1s;

  > * + * {
    margin-top: 8px;
  }

  > strong {
    color: #104673;
    font-size: 1.5rem;
    font-weight: 500;
  }

  > span {
    opacity: 0;
    color: #c53030;
    transition: opacity 0.5s ease;
  }

  > div {
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;

    height: 50px;
    border-radius: 8px;
    border: 2px solid #def2fe;

    transition: border-color 0.5s ease;

    > svg {
      width: 40px;
      height: 40px;
      color: #1b75bf;
      fill: #1b75bf;

      &:first-child {
        margin-left: 10px;
      }

      &.eye {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: calc(50% - 20px);
        opacity: 1;
        z-index: 25;
        transition: opacity 0.5s ease, filter 0.5s ease;

        &.hidden {
          opacity: 0;
          z-index: -1;
        }
      }
      transition: filter 0.5s ease;
      &:hover {
        filter: brightness(75%);
      }
    }
    &:focus {
      border-color: #2196f3;
    }
  }

  input {
    width: 100%;
    height: 100%;
    padding: 8px 60px 8px 12px;
    border-radius: 10px;
  }

  &.isErrored {
    > div {
      border-color: #c53030;
    }
    > span {
      opacity: 1;
    }
  }
`;

export const Button = styled.button`
  margin-top: 32px;
  height: 50px;
  background-color: #104673;
  color: #fff;
  border-radius: 10px;

  opacity: 0;

  animation: ${fadeIn} 0.5s ease forwards normal 1.1s;

  transition: background-color 0.5s ease;
  &:hover {
    background-color: #1b75bf;
  }
`;
