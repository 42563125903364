import styled, { css } from 'styled-components';

interface ModalProps {
  isShown: boolean;
}

export const BackDrop = styled.div<ModalProps>`
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
    `}
  background-color: rgba(9, 77, 121, .75);
  transition: top 0.5s ease;
  z-index: 45;
`;

export const Container = styled.section<ModalProps>`
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;

  display: flex;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
    `}

  transition: top .5s ease;
  z-index: 50;

  > svg {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;

    top: 28px;
    right: 28px;

    /* color: #fff;
    fill: #fff; */
    transition: filter 0.5s ease;

    &:hover {
      filter: brightness(85%);
    }
  }
`;

export const Modal = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px 3px 6px #aeaeae29;
  border-radius: 10px;

  width: 100%;
  max-width: 1000px;

  transition: all 0.5s ease;

  > header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #ececec;

    padding: 24px 32px;

    width: 100%;
    > strong {
      font-size: 1.5rem;
      color: #375fc4;
      text-transform: capitalize;
    }
  }

  > svg {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;

    color: #213a77;

    width: 24px;
    height: 24px;

    transition: color 0.5s ease;

    &:hover {
      color: #375fc4;
    }
  }

  > div {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      border-radius: 8px;
      color: #fff;
      padding: 4px 12px;
      background-color: hsl(0, 94%, 43%);
      transition: filter 0.5s ease;

      &:hover {
        filter: brightness(75%);
      }

      + button {
        background-color: hsl(203, 85%, 48%);
        margin-left: 16px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding: 16px;

  > div {
    + div {
      margin-top: 16px;
    }

    .ck-content {
      min-height: 150px;
      width: 100%;
      overflow-y: scroll;
      max-height: 200px;
    }

    select {
      max-width: 200px;
      border: 1px solid #aeaeae;
      width: 100%;
      height: 35px;
      /* padding: 8px 12px; */
      border-radius: 4px;

      transition: border-color 0.5s ease;
      &:focus {
        border-color: hsl(203, 85%, 48%);
      }
    }

    &:nth-child(1) {
      display: flex;
      > div {
        &:nth-child(1) {
          flex: 1;
          display: flex;
          flex-direction: column;
          > div {
            display: flex;
            flex-direction: column;
            + div {
              margin-top: 12px;
            }
            > span {
              font-size: 1.1rem;
              color: #375fc4;
              margin-bottom: 4px;
            }

            > input {
              border: 1px solid #aeaeae;
              width: 100%;
              height: 35px;
              padding: 8px 12px;
              border-radius: 10px;

              transition: border-color 0.5s ease;
              &:focus {
                border-color: hsl(203, 85%, 48%);
              }
            }
          }
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          width: 100%;
          margin-left: 15px;
          > span {
            font-size: 1.25rem;
            color: #375fc4;
            margin-bottom: 12px;
          }

          > div {
            &:not(:last-child) {
              display: flex;
              flex-direction: column;

              > div {
                height: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > button {
                  background-color: #fff;
                  padding: 2px 4px;
                  border-radius: 4px;
                  cursor: pointer;
                  border: 1px solid #000;
                  font-size: 1rem;
                  color: #375fc4;
                  margin-bottom: 4px;

                  flex: 1;
                  word-wrap: anywhere;

                  transition: filter 0.5s ease;
                  &:hover {
                    filter: brightness(75%);
                  }
                }

                > svg {
                  margin-left: 6px;
                  color: #ebe658;
                  fill: #ebe658;
                  width: 22px;
                  height: 22px;

                  &:last-child {
                    color: #e33834;
                    fill: #e33834;
                  }
                  &:hover {
                    filter: brightness(75%);
                  }
                }
              }
            }
            &:last-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: auto;

              > button {
                background-color: #375fc4;
                padding: 2px 4px;
                border-radius: 8px;
                font-size: 1.1rem;
                color: #fff;
                margin-bottom: 4px;

                transition: filter 0.5s ease;
                &:hover {
                  filter: brightness(75%);
                }
              }
            }
          }
        }
      }
    }

    &:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > select + select {
        margin-left: 12px;
      }
    }
  }
`;
